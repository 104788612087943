import React from "react"
import ProductPageHeroImage from "./productpageheroimage"
import BlindStylesGrid from "./blindstylesgrid"
import ShadeStylesGrid from "./shadestylesgrid"
import ShutterStylesGrid from "./shutterstylesgrid"

const getProductOptionsGrid = (pageTitle) => {
  if (pageTitle.includes('Window Blinds')) {
    return (
      <div>
        <BlindStylesGrid />
      </div> 
    )
  } else if (pageTitle.includes('Window Shades')) {
    return (
      <div>
        <ShadeStylesGrid />
      </div> 
    )
  } else if (pageTitle.includes('Window Shutters')) {
    return (
      <div>
        <ShutterStylesGrid />
      </div> 
    )
  }
}

const ProductPage = ( {page} ) => {
    const pageTitle = page.title
    return (
      <div className="container mx-auto font-opensans mt-5 px-6">
        <ProductPageHeroImage pageName={ pageTitle } />
        {getProductOptionsGrid(pageTitle)}
        <h1 className="mt-6"
          dangerouslySetInnerHTML={{ __html: page.title }}></h1>
        <div dangerouslySetInnerHTML={{ __html: page.content }} />

      </div>
    );
}

export default ProductPage