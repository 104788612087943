import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const HomePageHero = () => {
    return (
        <>
        <div className="md flex flex-wrap items-center h-auto text-darkcharcoal-default font-opensans mb-10">
            <div className="bg-white w-full md:w-1/3 h-auto">
                <div className="flex mx-6 justify-center text-center md:justify-start md:text-left">
                    <h1 className="text-3xl font-bold mt-10">Enrich your home with <span className="text-blue-700">beautiful blinds</span></h1>
                </div>
                <div className="flex mx-6 justify-center text-center md:justify-start md:text-left">
                    <h2 className="text-base font-bold mt-4">WinDecor provides beautiful blinds and shades for your Calgary area home.</h2>
                </div>
                <div className="flex mx-6 justify-center md:justify-start">
                    <button className="p-3 mt-3 mb-6 border-2 border-primary-default rounded bg-primary-default hover:bg-primary-darker hover:text-white font-semibold text-base shadow-sm transition ease-in duration-500">
                        <Link to="/scheduleconsult">
                            Get Free Estimate
                        </Link>
                    </button>
                </div>
            </div>
            <div className="bg-white w-full md:w-2/3 h-auto">
                <StaticImage 
                    src="../images/Calgary-WinDecor-Blinds-Drapes-Hero.jpeg" 
                    alt="" 
                    loading="eager" />
            </div>
        </div>
        </>
    );
}

export default HomePageHero