import React from "react"

const FrontPageTextBlock = ( {page} ) => {
   
    return (
        <div className="container mx-auto font-opensans mt-10 px-6">
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
    );
}

export default FrontPageTextBlock