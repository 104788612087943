import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ShadeStylesGrid = () => {
  return (
    <div className="container mx-auto mt-10 font-opensans">
        <div className="text-center">
            <h2 className="text-lg">Shade Choices / Styles</h2>
        </div>
        <div className="mt-10 grid justify-items-center grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/pirouette-shades'>
                <StaticImage 
                    src="../images/shadestylescards/Pirouette-Shade-Style.jpeg" 
                    alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Pirouette Shades
                    </div>
                    <p className="text-base">
                      An awe-inspiring design of shade from Hunter Douglas - fabric plus sheer.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/pirouette-shades' className="uppercase text-blue-700">
                      Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/silhouette-shades'>
                <StaticImage 
                src="../images/shadestylescards/Silhouette-Sheer-Shade-Style.jpeg" 
                alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Silhouette Sheer Shades
                    </div>
                    <p className="text-base">
                      Soft fabric vanes suspend between two layers of sheer - unobstructed views.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/silhouette-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/zebra-blinds'>
                <StaticImage 
                  src="../images/shadestylescards/Banded-Zebra-Shade-Style.jpeg" 
                  alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Banded or Zebra Shades
                    </div>
                    <p className="text-base">
                      Shade alternates between sheer and solid fabric bands.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/zebra-blinds' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/vignette-modern-roman'>
                <StaticImage 
                      src="../images/shadestylescards/Roman-Fabric-Shade-Style.jpg" 
                      alt="" 
                  />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Roman Shades
                    </div>
                    <p className="text-base">
                      A timeless desgin - classic flat style or richly folded waterfall style.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/vignette-modern-roman' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/cellular-shades'>
                <StaticImage 
                      src="../images/shadestylescards/Honeycomb-Shade-Style.jpeg" 
                      alt="" 
                  />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Cellular Shades
                    </div>
                    <p className="text-base">
                      Shades with energy efficiency/savings at the core - top-down feature
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/cellular-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/woven-wood-shades'>
                <StaticImage 
                      src="../images/shadestylescards/Woven-Wood-Shades-Style.jpg" 
                      alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Woven Wood Shades
                    </div>
                    <p className="text-base">
                      Handcrafted with reeds, grasses, bamboo and woods.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/woven-wood-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/roller-shades'>
                <StaticImage 
                      src="../images/shadestylescards/Roller-Shade-Style.jpeg" 
                      alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Roller Shades
                    </div>
                    <p className="text-base">
                      Layer textiles to add warmth and contemporary flair.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/roller-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/screen-solar-shades'>
                <StaticImage 
                      src="../images/shadestylescards/Screen-Roller-Shade-Style.jpeg" 
                      alt="" 
                  />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Screen Roller Shades
                    </div>
                    <p className="text-base">
                      Protect against glare and UV rays while maintaining outside views.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/screen-solar-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/solera-shades'>
                <StaticImage 
                        src="../images/shadestylescards/Solera-Soft-Shade-Style.jpeg" 
                        alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Solera Soft Shades
                    </div>
                    <p className="text-base">
                      Hybrid style shade - roman fold design blends with cellular construction
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/solera-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/solera-shades'>
                <StaticImage 
                        src="../images/shadestylescards/Cellular-Roller-Shade-Style.jpeg" 
                        alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Cellular Roller
                    </div>
                    <p className="text-base">
                      Roller shade design combined with cell design for better insulation.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/solera-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/pleated-shades'>
                <StaticImage 
                        src="../images/shadestylescards/Pleated-Shade-Style.jpg" 
                        alt="" />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Pleated Shades
                    </div>
                    <p className="text-base">
                      Offer elegant looks to your windows with crisp pleats and clean looks.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/pleated-shades' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
              <Link to='/products/shades/luminette'>
                <StaticImage 
                        src="../images/shadestylescards/Luminette-Shade-Style.jpeg" 
                        alt="" 
                    />
              </Link>
                <div className="px-6 py-2">
                    <div className="font-bold text-lg mb-2">
                      Luminette Vertical Shades
                    </div>
                    <p className="text-base">
                      Sheer vanes with drapery effect - an elegant style for patio doors.
                    </p>
                </div>
                <div className="px-6 py-2">
                    <Link to='/products/shades/luminette' className="uppercase text-blue-700">
                        Learn More
                    </Link>
                </div>
            </div>
            
        </div>
    </div>

);
}

export default ShadeStylesGrid