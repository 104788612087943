import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PromotionBlocks = () => {

    return (
        <div className="container mx-auto mt-10 font-opensans">
            <div className="text-center">
                <h2 className="text-lg">Current Promotions</h2>
            </div>
            <div className="grid justify-items-center grid-cols-1 grid-rows-3">
                <div className="mt-4 bg-white overflow-hidden lg:flex lg:items-center lg:justify-center max-w-xs md:max-w-2xl lg:max-w-full shadow rounded">
                    <div className="lg:w-1/2 lg:max-h-72 h-auto">
                        <StaticImage 
                            src="../images/promotions/Natural-Shades-Free-Cordless.jpg" 
                            alt=""
                            className="lg:rounded h-auto"
                        />
                    </div>
                    <div className="lg:w-1/2 text-center">
                        <h2 className="text-base py-2 font-bold">FREE Upgrade to <span className="text-blue-700">Cordless Lift</span></h2>
                        <p>Spectacular Savings</p>
                        <p>On Beautiful Graber Pleated, Natural, Roller, and Roman Shades</p>
                        <p>Valid: January 1 – April 21, 2021</p>
                    </div>
                </div>
                <div className="mt-8 bg-white overflow-hidden lg:flex lg:items-center lg:justify-center max-w-xs md:max-w-2xl lg:max-w-full shadow rounded">
                    <div className="lg:w-1/2 text-center">
                        <h2 className="text-base py-2 font-bold"><span className="text-blue-700"> $150 Rebate</span> Savings Available</h2>
                        <p>2 Silhouette or 2 Pirouette Shadings or 1 Luminette</p>
                        <p>Plus $50 or $150 rebate for each additional unit</p>
                        <p>Valid: April 10 - June 21, 2021</p>
                    </div>
                    <div className="lg:w-1/2 lg:max-h-72">
                        <StaticImage 
                            src="../images/promotions/Silhouette-Window-Shade-Sheer.jpeg" 
                            alt=""
                            className="lg:rounded h-auto"
                        />
                    </div>
                </div>
                <div className="mt-4 bg-white overflow-hidden lg:flex lg:items-center lg:justify-center max-w-xs md:max-w-2xl lg:max-w-full shadow rounded">
                    <div className="lg:w-1/2 lg:max-h-72 h-auto">
                        <StaticImage 
                            src="../images/promotions/Cellular-Shades-Top-Down.jpg" 
                            alt=""
                            className="lg:rounded h-auto"
                        />
                    </div>
                    <div className="lg:w-1/2 text-center">
                        <h2 className="text-base py-2 font-bold">Buy More | <span className="text-blue-700">Save More</span></h2>
                        <p>15% OFF - 15+ custom blinds and shades</p>
                        <p>On Graber Custom Blinds and Shades</p>
                        <p>Valid: January 1 - April 30, 2021</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromotionBlocks