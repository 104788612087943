import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ProductGrid = () => {

    return (
        <div className="container mx-auto mt-10 font-opensans">
            <div className="text-center">
                <h2 className="text-lg">Beautiful Blind Choices / Products</h2>
            </div>
            <div className="mt-10 grid justify-items-center grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/blinds'>
                        <StaticImage 
                            src="../images/productcards/Wood-Blinds-Product-WinDecor.png" 
                            alt="" 
                        />
                    </Link>    
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Blinds
                        </div>
                        <p className="text-base">
                            Window Blinds are tilt opened and tilt closed—made of wood, faux wood, vinyl or aluminum slats.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/shades'>
                        <StaticImage 
                            src="../images/productcards/Sheer-Shades-Product-WinDecor.jpeg" 
                            alt="" 
                        />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Shades
                        </div>
                        <p className="text-base">
                            Modern-day window shades are versatile—bring fabric elegance and preserve views.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/shades' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/shutters'>
                        <StaticImage 
                            src="../images/productcards/Shutter-Window-Covering-Product.png" 
                            alt="" 
                        />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Shutters
                        </div>
                        <p className="text-base">
                            Shutters offer streamlined looks and rich curb appeal—beauty, style, and easy to clean.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/shutters' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/window-curtains-drapes'>
                        <StaticImage 
                            src="../images/productcards/Curtains-Drapes-Product-WinDecor.jpeg" 
                            alt="" 
                        />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Drapes
                        </div>
                        <p className="text-base">
                            Window Drapes or Curtains create a design that is timeless—decorative panels with rods and rings.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/window-curtains-drapes' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/motorized-blinds-and-shades'>
                        <StaticImage 
                            src="../images/productcards/Motorized-Blinds-Product-WinDecor.png" 
                            alt="" 
                        />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Motorized Blinds
                        </div>
                        <p className="text-base">
                            Motorized Blinds—Operate the shade using a remote, mobile app, or voice commands.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/motorized-blinds-and-shades' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                <StaticImage 
                        src="../images/productcards/Sliding-Glass-Door-Blind-WinDecor.jpeg" 
                        alt="" 
                    />
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Sliding Door Blinds
                        </div>
                        <p className="text-base">
                            Slide-Away blinds for patio doors or wide expanse windows — keep traffic flowing. 
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/sliding-glass-door-blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default ProductGrid