import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ShutterStylesGrid = () => {
    return (
        <div className="container mx-auto mt-10 font-opensans">
            <div className="text-center">
                <h2 className="text-lg">Shutter Choices / Styles</h2>
            </div>
            <div className="mt-10 grid justify-items-center grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/shutters/shutter'>
                        <StaticImage 
                            src="../images/shutterstylescards/Wood-Shutter-MasterBed-Style.jpg" 
                            alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Wood Shutters
                        </div>
                        <p className="text-base">
                            Finest North American basswood material - Unique
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/shutters/shutter' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/shutters/shutter'>
                        <StaticImage 
                        src="../images/shutterstylescards/Composite-Wood-Shutter-Style.jpg" 
                        alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Composite Wood Shutters
                        </div>
                        <p className="text-base">
                            Use engineered polymer material - as beautiful as real wood.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/shutters/shutter' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/shutters/shutter'>
                        <StaticImage 
                        src="../images/shutterstylescards/Vinyl-Shutter-Style.jpeg" 
                        alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Vinyl Shutters
                        </div>
                        <p className="text-base">
                            Made of strong poly-resin material - easy to care and clean.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/shutters/shutter' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShutterStylesGrid