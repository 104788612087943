import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const BlindStylesGrid = () => {
 
    return (
        <div className="container mx-auto mt-10 font-opensans">
            <div className="text-center">
                <h2 className="text-lg">Blind Choices / Styles</h2>
            </div>
            <div className="mt-10 grid justify-items-center grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/blinds/wood-blinds'>
                        <StaticImage 
                            src="../images/blindstylescards/Wood-Blind-Style.png" 
                            alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Wood Blinds
                        </div>
                        <p className="text-base">
                            Slats made from real wood - visible wood grains, rich stain colors.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/blinds/wood-blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/blinds/faux-wood-blinds'>    
                        <StaticImage 
                            src="../images/blindstylescards/Composite-Wood-Blind-Style.png" 
                            alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Composite Wood Blinds
                        </div>
                        <p className="text-base">
                            Slat are made of modern materials - enhanced durability
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/blinds/faux-wood-blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/blinds/faux-wood-blinds'>
                        <StaticImage 
                        src="../images/blindstylescards/Faux-Wood-Blind-Style.jpeg" 
                        alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Faux Wood Blinds
                        </div>
                        <p className="text-base">
                            A cost-effective choice - both solid and wood-grain colors available.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/blinds/faux-wood-blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/blinds/aluminum-blinds'>
                        <StaticImage 
                        src="../images/blindstylescards/Aluminum-Blind-Style.jpeg" 
                        alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Aluminum Blinds
                        </div>
                        <p className="text-base">
                            Aluminum slats available in modern-day finishes, textures, and colors.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/blinds/aluminum-blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/blinds/vinyl-blinds'>
                        <StaticImage 
                            src="../images/blindstylescards/Vinyl-Blind-Style.jpg" 
                            alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Vinyl Blinds
                        </div>
                        <p className="text-base">
                            Slats made from designer vinyl material - pairs well with vertical blinds.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/blinds/vinyl-blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="max-w-xs rounded overflow-hidden shadow-lg my-2">
                    <Link to='/products/blinds/vertical-blinds'>
                        <StaticImage 
                            src="../images/blindstylescards/Vertical-Blind-Style.jpeg" 
                            alt="" />
                    </Link>
                    <div className="px-6 py-2">
                        <div className="font-bold text-lg mb-2">
                            Vertical Blinds
                        </div>
                        <p className="text-base">
                            Vertical vanes move from side to side - sliding patio door openings.
                        </p>
                    </div>
                    <div className="px-6 py-2">
                        <Link to='/products/blinds/vertical-blinds' className="uppercase text-blue-700">
                            Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default BlindStylesGrid