import React from "react"
import { StaticImage } from "gatsby-plugin-image"

/*
const getProductPageHeroMedia = graphql`
query {
    zebraHeroImage: file(relativePath: { eq: "shadespagesheroimages/zebra-blinds-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pirouetteHeroImage: file(relativePath: { eq: "shadespagesheroimages/pirouette-blinds-hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    silhouetteHeroImage: file(relativePath: { eq: "shadespagesheroimages/silhouette-blinds-hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    romanHeroImage: file(relativePath: { eq: "shadespagesheroimages/roman-shades-hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cellularHeroImage: file(relativePath: { eq: "shadespagesheroimages/Cellular-Shades-Honeycomb-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wovenHeroImage: file(relativePath: { eq: "shadespagesheroimages/Woven-Wood-Shades-Natural-Hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    rollerHeroImage: file(relativePath: { eq: "shadespagesheroimages/Roller-Blind-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    screenrollerHeroImage: file(relativePath: { eq: "shadespagesheroimages/Screen-Roller-Shade-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    solerashadeHeroImage: file(relativePath: { eq: "shadespagesheroimages/Solera-Soft-Shades-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pleatedshadeHeroImage: file(relativePath: { eq: "shadespagesheroimages/Pleated-Shades-Hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    luminetteshadeHeroImage: file(relativePath: { eq: "shadespagesheroimages/Luminette-Vertical-Patio-Door-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    woodblindspageHeroImage: file(relativePath: { eq: "blindspagesheroimages/Wood-Blinds-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    compositewoodblindspageHeroImage: file(relativePath: { eq: "blindspagesheroimages/Composite-Wood-Blind-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    aluminumblindspageHeroImage: file(relativePath: { eq: "blindspagesheroimages/Aluminum-Blinds-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vinylblindspageHeroImage: file(relativePath: { eq: "blindspagesheroimages/Vinyl-Blinds-Hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    verticalblindspageHeroImage: file(relativePath: { eq: "blindspagesheroimages/Vertical-Blind-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    blindspageHeroImage: file(relativePath: { eq: "Wood-Blinds-Living-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    shadespageHeroImage: file(relativePath: { eq: "Shades-Sheer-Living-Room-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    shutterpageHeroImage: file(relativePath: { eq: "Window-Shutters-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    drapespageHeroImage: file(relativePath: { eq: "Window-curtains-drapes-Hero.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    motorizedblindspageHeroImage: file(relativePath: { eq: "Motorized-Blinds-Shades-Hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    thankyouHeroImage: file(relativePath: { eq: "thank-you.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
}
`
*/

const ProductPageHeroImage = ( {pageName} ) => {
 
    if (pageName === 'Zebra Blinds') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/zebra-blinds-hero.jpg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Pirouette Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/pirouette-blinds-hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Silhouette Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/silhouette-blinds-hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Modern Roman Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/roman-shades-hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Cellular Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/Cellular-Shades-Honeycomb-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Woven Wood Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/Woven-Wood-Shades-Natural-Hero.jpg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Roller Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/Roller-Blind-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Screen Solar Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/Screen-Roller-Shade-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Solera Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/Solera-Soft-Shades-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Pleated Shades') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/Pleated-Shades-Hero.jpg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Luminette') {
      return (
        <div>
          <StaticImage 
            src="../images/shadespagesheroimages/Luminette-Vertical-Patio-Door-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Wood Blinds') {
      return (
        <div>
          <StaticImage 
            src="../images/blindspagesheroimages/Wood-Blinds-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Faux Wood Blinds') {
      return (
        <div>
          <StaticImage 
            src="../images/blindspagesheroimages/Composite-Wood-Blind-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Aluminum Blinds') {
      return (
        <div>
          <StaticImage 
            src="../images/blindspagesheroimages/Aluminum-Blinds-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Vinyl Blinds') {
      return (
        <div>
          <StaticImage 
            src="../images/blindspagesheroimages/Vinyl-Blinds-Hero.jpg" 
            alt="" />
        </div>
      )
    } else if (pageName === 'Vertical Blinds') {
      return (
        <div>
          <StaticImage 
            src="../images/blindspagesheroimages/Vertical-Blind-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName.includes('Window Blinds')) {
      return (
        <div>
          <StaticImage 
            src="../images/Wood-Blinds-Living-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName.includes('Window Shades')) {
      return (
        <div>
          <StaticImage 
            src="../images/Shades-Sheer-Living-Room-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName.includes('Window Shutters')) {
      return (
        <div>
          <StaticImage 
            src="../images/Window-Shutters-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName.includes('Window Curtains Drapes')) {
      return (
        <div>
          <StaticImage 
            src="../images/Window-curtains-drapes-Hero.jpeg" 
            alt="" />
        </div>
      )
    } else if (pageName.includes('Motorized Blinds and Shades')) {
      return (
        <div>
          <StaticImage 
            src="../images/Motorized-Blinds-Shades-Hero.jpg" 
            alt="" />
        </div>
      )
    } else {
      return (
        <div>
         
        </div>
      )
    }
}

export default ProductPageHeroImage
