import React from "react"

const Testimonials = () => {
    return (
        <div className="container mx-auto mt-10 font-opensans">
            <div className="text-center">
                <h2 className="text-lg">What our Clients Say / Testimonials</h2>
            </div>
            <div className="grid justify-items-center gap-8 grid-cols-1 md:grid-cols-2 md:grid-rows-2">
                <div className="mt-4 px-4 bg-white overflow-hidden max-w-xs md:max-w-2xl lg:max-w-full shadow rounded-md">
                    <div className="mt-4 text-base font-semibold">
                        Lovely•friendly•people
                    </div>
                    <div className="mt-1 text-sm">
                        "Joseph Aisekhalaye"
                    </div>
                    <div className="mt-4">
                        Your installers have just been and now left after installing my blinds. I just wanted to say thank you so much, they were lovely friendly people, quick, efficient and I am very happy, the blinds look great ! I have been very impressed in all my dealings with your company and will definitely recommend your services. Thanks again !
                    </div>
                </div>
                <div className="mt-4 px-4 bg-white overflow-hidden max-w-xs md:max-w-2xl lg:max-w-full shadow rounded-md">
                    <div className="mt-4 text-base font-semibold">
                        Second•To•None
                    </div>
                    <div className="mt-1 text-sm">
                        "Patty Carr "
                    </div>
                    <div className="mt-4">
                        I want to thank you for the services that you have provided, you have exceeded our expectations. The product, the service is second to none. Please pass on my thanks to your wonderful installers. I would strongly recommend your company. Thanks again.
                    </div>
                </div>
                <div className="mt-4 px-4 bg-white overflow-hidden max-w-xs md:max-w-2xl lg:max-w-full shadow rounded-md">
                    <div className="mt-4 text-base font-semibold">
                        Treated•So•Well
                    </div>
                    <div className="mt-1 text-sm">
                        "Eileen Dooley"
                    </div>
                    <div className="mt-4">
                        I want to tell you happy we are with the superb service you gave us. There is no way I could find fault with anything you did. It was so nice to be treated so well. I will not hesitate to recommend you to anyone. Thank you so much.
                    </div>
                </div>
                <div className="mt-4 px-4 bg-white overflow-hidden max-w-xs md:max-w-2xl lg:max-w-full shadow rounded-md">
                    <div className="mt-4 text-base font-semibold">
                        GREAT•Prices•Experience!
                    </div>
                    <div className="mt-1 text-sm">
                        "Lori Shackleton"
                    </div>
                    <div className="mt-4">
                        I had a great experience with Windecor - excellent help selecting what was needed, terrific installation service, friendly helpful service throughout the process, and GREAT prices! The finished work looks wonderful. I have told many people about Windecor and recommend them to all.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonials