import React from "react"
import Layout from "../components/layout"
//import SEO from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from "gatsby"
import FrontPageTextBlock from "../components/frontpagetextblock"
import HomePageHero from "../components/homepagehero"
import ProductGrid from "../components/productgrid"
import PromotionBlocks from "../components/promotionblocks"
import ProductPage from "../components/productpage"
import Testimonials from "../components/testimonials"

export const query = graphql`
query GET_PAGE($id: String!) {
  wpPage(id: {eq: $id}) {
    title
    isFrontPage
    content
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
  }
}
`

const PageTemplate = ( {data} ) => {
  const page = data.wpPage;
  if (page.isFrontPage) {
    return ( 
      <Layout>
      <Seo post={page} />
      <HomePageHero />
      <ProductGrid />
      <PromotionBlocks />
      <Testimonials />
      <FrontPageTextBlock page={page} />
      </Layout>
      );
  } else {
    return (
      <Layout>
        <Seo post={page} />
        <ProductPage page={page} />
      </Layout>
    );
  }
}

export default PageTemplate